<script>
import { discountList, discountListContent } from "@/api/discount";

export default {
    name:'Promotion',
    data() {
        return {
            actionList: [],
            header:{
                mainTitle3: getWord('sale_event')
            }
        };
    },
    methods: {
        loadList() {
            discountList()
                .then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.actionList = result.data.result;

                        this.CustomScroll(this.$refs.promotion.id);
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                })
        }
    },
    components: {
        Header: () => import('components/base/Header'),
    },
    mounted() {
        this.loadList();
        if (this._TEMPLATE==='template-1'||this._TEMPLATE==='template-2') {
            this.$root.$emit('closeBottomTab', false)
        }
    },
    beforeRouteLeave(to, from, next){
        if (this._TEMPLATE==='template-1'||this._TEMPLATE==='template-2') {
            this.$root.$emit('closeBottomTab', true)
        }
        next();
    },
};
</script>
<template>
    <section id="promotion" ref="promotion" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <Header 
                :mainTitle="getWord('promotion_centre')"
                :backButton="_TEMPLATE==='template-1'" 
            />
            <div class="discount_" v-if="actionList != ''">
                <ul class="home_kqja ">
                    <li class="home_kqja_img" v-for="(it,i) in actionList" :key="i"><img :src="it.img" @click="$router.push('/action/discountCon/'+it.id)"></li>
                </ul>
            </div>
            <span v-else><img class="noDisPhoto" src="@@/assets/images2/noDis.png" /><br />
                <p style="top:55%;width:100%;text-align:center;position:absolute;font-size:0.28rem;color:#666;">{{ getWord('no_more_promotion') }}</p>
            </span>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header 
                :mainTitle="header.mainTitle3" 
                :backButton=false 
            />
            <ul>
                <li v-for="item in actionList">
                    <img :src="item.img" @click="$router.push('/action/discountCon/'+item.id)" />
                    <h4><i class="iconfont icon-back"></i>{{item.title}}</h4>
                </li>
            </ul>
        </template>
    </section>
</template>
<style lang='scss' scoped>
#promotion {
    overflow: auto;
    height: 100%;

    &.template-3 {

        ul {
            padding:.25rem;

            li {
                list-style-type: none;
                margin-bottom: .25rem;

                img {
                    margin:0;
                    height: auto;
                    width: 100%;
                    max-height: 1.6rem;
                }

                h4 {
                    font-size: .3rem;
                    font-weight: normal;

                    .iconfont {
                        float: right;
                        transform: rotateZ(180deg);
                    }
                }
            }
        }
    }
}

.noDisPhoto {
    width: 4rem;
    height: 3rem;
    top: 25%;
    left: 22%;
    position: absolute;

}

.discount_ {
    
}

.discount_ ul li {
    list-style: none;
}

img {
    margin-left: 2.5%;
    margin-top: 3%;
    width: 7.12rem;
    height: 1.96rem;
}
</style>